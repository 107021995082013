@media (min-width: 1025px) {
  .container {
    background-image: url("../../../assets/images/7.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .fallbackBg {
    background-image: url("../../../assets/images/7.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .videoBg {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  .content {
    width: 90%;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 0 auto;
    gap: 8dvw;
    padding: 0 3.48dvw;
  }
  .content > img {
    width: 26dvw;
    height: 26dvw;
  }

  .centuries {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 50%;
  }
  .tokenColumn {
    display: flex;
    flex-direction: column;
    gap: 2dvw;
  }
}

@media (min-width: 1550px) {
  .tokenText {
    right: 12%;
  }
}

@media (max-width: 1025px) {
  .container {
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../../../assets/images/fon-sectionscriber.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .content {
    width: 90%;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    gap: 8dvw;
    padding: 0 3.48dvw;
  }
  .content > img {
    width: 39.3dvw;
  }
  .centuries {
    display: grid;
    gap: 5.976dvw;
  }
}
