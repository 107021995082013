.container {
    width: 90%;
    display: grid;
    gap: 3.125dvw;
    padding-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 10dvw;
}
ul {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    display: grid;
    gap: 3.5dvw;
}
ul li a {
    display: block;
    text-decoration: none;
    color: var(--color-text-black);
    font-size: 22px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -1%;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    cursor: pointer;
}
.link {
    display: grid;
    gap: 1.56dvw;
}
.link > a {
    display: block;
    text-decoration: none;
    color: var(--color-text-grey);
    font-size: 22px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -1%;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    cursor: pointer;
}
@media (min-width: 1025px) {
    .container {
        padding-top: 0;
    }
    ul {
        gap: 1.56dvw;
    }
    ul li a {
        font-size: 1.45dvw;
        line-height: 1.77dvw;
    }
    .link > a {
        font-size: 1.14dvw;
        line-height: 1.25dvw;
    }
}
