.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.3dvw;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1025px) {
  .grid {
    width: 75%;
    column-gap: 0.46dvw;
    flex-wrap: wrap;
  }
}

@media (max-width: 400px) {
  .grid {
    width: 75%;
    column-gap: 8px;
  }
}
