.container {
  width: 100%;
  height: 100dvh;
  background-image: url("../.././assets-web3/image/fon-section-1-mobile-web3.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content {
  width: 90%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 11dvw;
  gap: 6dvw;
}
.bgPromotion {
  width: 90%;
  height: 9.3dvw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    90deg,
    rgba(181, 124, 255, 0.4) 0%,
    rgba(253, 82, 253, 0.5) 50%,
    rgba(181, 124, 255, 0.4) 100%
  );
  backdrop-filter: blur(1px);
  border-radius: 20px;
}
.textPomotion {
  width: 100%;
}
.logoS {
  width: 37.069dvw;
}
.blockAnimation {
  width: 220px;
}
.blckSocial {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.32dvw;
}

.centuries {
  display: grid;
  gap: 5.976dvw;
}

@media (min-width: 1025px) {
  .logoS {
    display: none;
  }
  .container {
    width: 100%;
    height: 100%;
    background-image: none;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .content {
    width: 90%;
    height: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding-top: 0;
    gap: 1.7dvw;
  }
  .bgPromotion {
    width: fit-content;
    height: auto;
    padding: 8px 26px;
    background: rgba(0, 0, 0, 0.68);
    border-radius: 20px;
  }
  .textPomotion {
    width: 100%;
  }

  .blckSocial {
    justify-content: flex-start;
    gap: 0.52dvw;
  }
}
