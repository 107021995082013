.container {
  width: 100%;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.fallbackBg {
  background-image: url("../../../assets/images/fon-section-4-mobile.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.videoBg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.content {
  width: 90%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  gap: 5dvw;
  overflow: hidden;
}
.content > img {
  width: 60.46dvw;
}
.centuries {
  display: flex;
  gap: 4dvw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blockInfo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.imageContainer {
  width: 40dvw;
}
.manImage {
  width: 46.97dvw;
  height: 97.67dvw;
}

.blockText {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 5.27dvw;
}
.blockAnimation {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 2.3dvw;
  margin-left: 5%;
  box-sizing: border-box;
  position: absolute;
  bottom: 10dvw;
  left: 0;
}

@media (min-width: 1025px) {
  .container {
    justify-content: space-between;
  }
  .content {
    width: 90%;
    flex-direction: row;
    align-items: flex-end;
    min-height: 100dvh !important;
    margin-bottom: 8.3dvw;
  }
  .centuries {
    width: 45%;
    gap: 1.25dvw;
    align-items: flex-start;
  }
  .blockInfo {
    width: 50%;
    justify-content: center;
    align-items: flex-start;
  }
  .manImage {
    position: relative;

    display: block;
    width: 17.44dvw;
    height: 39.89dvw;
    z-index: 99999;
    margin-bottom: 9dvw;
  }
  .blockAnimation {
    width: 50%;
    height: auto;
    gap: 1.6dvw;
    margin-top: 4dvw;
    margin-left: 0;
    position: static;
  }
  .manImage {
    width: 17.44dvw;
    margin-bottom: 0;
  }
  .blockText {
    gap: 4dvw;
  }
}
