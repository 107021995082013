* {
  box-sizing: border-box;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  height: 100dvh;
  gap: 56px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  background: linear-gradient(to bottom, var(--primary-green) 50%, #fff 50%);
}
.left {
  order: 2;
}
.right {
  order: 1;
}
.content {
  width: clamp(258px, 29.5vw, 493px);
  display: grid;
  gap: 3.2dvw;
  margin-left: 5%;
  padding-bottom: 9.25%;
}
.images {
  width: 90%;
  height: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerImages {
  max-width: 760px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6.5dvw;
}
.imageWrapper {
  position: relative;
}
.imageWrapper > img {
  width: 24.6dvw;
  height: 24.6dvw;
  display: block;
}
.hoverOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageWrapper:hover .hoverOverlay {
  opacity: 1;
}
/* Фоновая картинка плеера */
.hoverBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Иконка плеера */
.playIcon {
  position: relative;
  width: 30%; /* размер можно менять по необходимости */
  height: auto;
  z-index: 1;
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
  .container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    background: linear-gradient(to right, #fff 50%, var(--primary-green) 50%);
  }
  .left {
    order: 1;
  }
  .right {
    order: 2;
  }
  .content {
    /* width: 25.7dvw; */
    /* max-width: 493px; */
    margin-left: 5%;
    display: grid;
    gap: 1.15dvw;
    margin-bottom: 0;
  }
  .images {
    width: 50%;
    height: 100vh;
    margin: 0;
  }
  .containerImages {
    max-width: 760px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3.697dvw;
  }
  .imageWrapper > img {
    width: 9dvw;
    height: 9dvw;
  }
}
