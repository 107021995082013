.section5 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.container {
  width: 100%;
  margin-top: 12dvw;
  position: relative;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4.645dvw;
}
.button {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  font-size: 32px;
  height: 100vh;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
  width: 6dvw;
  object-fit: contain;
}
.content {
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.line {
  width: 2px;
  background-color: #c4c2c273;
}
.block1,
.block2 {
  width: 100%;
  background: white;
  padding: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 9.3dvw;
  align-items: start;
}
.centuries {
  display: grid;
  gap: 5.8dvw;
}
.centuries > h2 {
  font-size: 24px;
  line-height: 26px;
}
.centuries > h2 > span {
  display: inline-block;
  color: var(--primary-green);
}
.p {
  display: flex;
  align-items: center;
  gap: 1.8dvw;
}
.p > img {
  width: 6.511dvw;
}
.blockButtons {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14dvw;
}
.fon {
  height: 93.01dvw;
  width: 100%;
  margin-top: 9.645dvw;
}
.button {
  display: none;
}
@media (min-width: 1025px) {
  .container {
    margin-top: 4.0625dvw;
    width: 90%;
  }
  .content {
    flex-direction: row;
  }
  .block1,
  .block2 {
    width: 33.718dvw;
    background: white;
    padding: 24px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 3.125dvw;
    align-items: start;
  }
  .button {
    display: block;
  }
  .section5 {
    justify-content: flex-end;
    align-items: flex-end;
  }
  .centuries {
    gap: 0.9375dvw;
  }
  .centuries > h2 {
    font-size: 32px;
    line-height: 32px;
  }
  .blockButtons {
    justify-content: flex-end;
    gap: 1.25dvw;
  }
  .p {
    gap: 0.625dvw;
  }
  .p > img {
    width: 1.458dvw;
  }
  .fon {
    height: 24.218dvw;
    width: 98%;
    margin-top: 3.64dvw;
  }
}
