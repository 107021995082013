.container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  height: 100dvh;
  background-image: url("../../../assets/images/face-our-mobil.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.blockContent {
  width: 50%;
  padding-left: 5%;
  padding-bottom: 14.25%;
}
.content {
  width: clamp(258px, 26vw, 493px);
  display: grid;
  gap: 3.2dvw;
  margin-top: 4.65dvw;
}
.forbes {
  animation: fadeInOut 3s ease-in-out infinite;
}
.image {
  display: none;
}
.imgAudio {
  position: absolute;
  bottom: 12.25dvw;
  right: 5%;
}
.forbes {
  margin-bottom: 4.65dvw;
  animation: fadeInOut 4.5s ease-in-out infinite;
}
@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
  .container {
    align-items: center;
    justify-content: space-between;
    height: auto;
    background-image: none;
  }
  .image {
    display: block;
    margin: 0;
    width: 50%;
    height: 100vh;
    position: relative;
  }
  .blockContent {
    padding-bottom: 0;
    margin-bottom: 4.17dvw;
  }
  .content {
    gap: 1.15dvw;
    margin-top: 0;
  }
  .imgAudio {
    bottom: 4.17dvw;
  }
}
