.slideshowContainer9 {
    position: relative;
    width: 100%;
    height: 35px;
    overflow: hidden;
}

.slideshowContainer9 img {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 25px;
    opacity: 0;
    animation: fadeInOut9 27s linear infinite;
}

.slideshowContainer9 img:nth-child(1) {
    animation-delay: 0s;
}
.slideshowContainer9 img:nth-child(2) {
    animation-delay: 3s;
}
.slideshowContainer9 img:nth-child(3) {
    animation-delay: 6s;
}
.slideshowContainer9 img:nth-child(4) {
    animation-delay: 9s;
}
.slideshowContainer9 img:nth-child(5) {
    animation-delay: 12s;
}
.slideshowContainer9 img:nth-child(6) {
    animation-delay: 15s;
}
.slideshowContainer9 img:nth-child(7) {
    animation-delay: 18s;
}
.slideshowContainer9 img:nth-child(8) {
    animation-delay: 21s;
}
.slideshowContainer9 img:nth-child(9) {
    animation-delay: 24s;
}

@keyframes fadeInOut9 {
    0% {
        opacity: 0;
    }
    3.7% {
        opacity: 1;
    }
    7.4% {
        opacity: 1;
    }

    11.111% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}
/* ====================== */
/*      5 картинок       */
/* ====================== */
.slideshowContainer5 {
    position: relative;
    width: 100%;
    height: 35px; /* По желанию меняй тут или через style проп */
    overflow: hidden;
}

.slideshowContainer5 img {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 25px;
    opacity: 0;
    animation: fadeInOut5 15s linear infinite;
}

/* Задержки для 5 картинок (каждые 3 секунды) */
.slideshowContainer5 img:nth-child(1) {
    animation-delay: 0s;
}
.slideshowContainer5 img:nth-child(2) {
    animation-delay: 3s;
}
.slideshowContainer5 img:nth-child(3) {
    animation-delay: 6s;
}
.slideshowContainer5 img:nth-child(4) {
    animation-delay: 9s;
}
.slideshowContainer5 img:nth-child(5) {
    animation-delay: 12s;
}

@keyframes fadeInOut5 {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    15% {
        opacity: 1;
    }
    20% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
.slideshowContainer2 {
    position: relative;
    width: 100%;
    height: 35px; /* подгони под свои нужды */
    overflow: hidden;
}

.slideshowContainer2 img {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 25px;
    opacity: 0;
    animation: fadeInOut2 6s linear infinite; /* 6s на 2 картинки */
}

/* Сдвигаем вторую картинку на 3 секунды */
.slideshowContainer2 img:nth-child(1) {
    animation-delay: 0s;
}
.slideshowContainer2 img:nth-child(2) {
    animation-delay: 3s;
}

@keyframes fadeInOut2 {
    0% {
        opacity: 0;
    }
    15% {
        opacity: 1;
    }
    35% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
