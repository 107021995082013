.h1 {
}
.h2 {
  /* text-transform: uppercase; */
  font-family: Poppins;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  font-weight: 600;
  width: 100%;
}

.h3 {
  font-family: Helvetica Neue;
  font-weight: 700;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
@media (min-width: 1025px) and (max-height: 700px) {
  .h2,
  .h1,
  .h3 {
    font-size: 35px;
  }
}
