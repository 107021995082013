.card {
    width: 19.53dvw;
    height: 8.47dvw;
    display: flex;
    gap: 1.8dvw;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
    opacity: 0.42;
    padding: 1.2dvw 2.1dvw;
}

.selected {
    background-color: var(--primary-green);
}
.text {
    max-width: 70px;
    text-align: left;
}
.icon {
    width: 5.58dvw;
    height: 5.58dvw;
}
@media (min-width: 1025px) {
    .card {
        width: 4.375dvw;
        height: 1.875dvw;
        gap: 0.41dvw;
        padding: 1.2dvw 2.5dvw;
    }
    .icon {
        width: 1.25dvw;
        height: 1.25dvw;
    }
}
