

.item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.bullet {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
}

@media (min-width: 1025px) {
    .bullet {
    display: inline-block;
    width: 24px;
    height: 24px;
    }
} 



@media (min-width: 1025px) and (max-width: 1440px){
    .bullet {
    display: inline-block;
    width: 12px;
    height: 12px;
    }
} 

@media (max-width: 395px) {
    .item {
        font-size: 12px;
    }
}
