.container {
  width: 100%;
}
.block2 {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 15%;
  gap: 8.3dvw;
  background-color: var(--primary-green);
}
.contactBlock {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  gap: 2.7dvw;
  margin-top: 10dvw;
}
.icons {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10.697dvw;
}
.icon {
  width: 4.65dvw;
  cursor: pointer;
}
.teamBlock {
  width: 90%;
  display: grid;
  gap: 10dvw;
}
.teamMember {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5.58dvw;
}
.avatar {
  width: 22.7dvw;
}
.memberInfo {
  width: 57.67dvw;
  gap: 2dvw;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1025px) {
  .container {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    /* padding-bottom: 3.8dvw; */
  }
  .block2 {
    width: 55%;
    height: auto;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 3.5dvw;
    padding-left: 10dvw;
    margin-bottom: 10%;
    padding-bottom: 0;
  }
  .contactBlock {
    width: 18.22dvw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    gap: 1dvw;
    margin: 0;
  }

  .icons {
    display: flex;
    justify-content: flex-start;
    gap: 1.666dvw;
  }

  .icon {
    width: 2.08dvw;
    cursor: pointer;
  }

  .teamBlock {
    display: grid;
    gap: 2dvw;
  }

  .teamMember {
    width: 26.09dvw;
    display: flex;
    align-items: center;
    gap: 1.25dvw;
  }

  .avatar {
    width: 5.625dvw;
  }

  .memberInfo {
    width: 19.218dvw;
    gap: 0.7dvw;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1025px) and (max-height: 860px) {
  .container {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    /* padding-bottom: 2dvw; */
  }
  .block2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3dvw;
    padding-left: 10dvw;
    justify-content: center;
  }
  .contactBlock {
    width: 20.22dvw;
    gap: 4px;
    margin: 0;
  }

  .icons {
    display: flex;
    justify-content: flex-start;
    gap: 1.6dvw;
  }
  .teamBlock {
    display: grid;
    gap: 1dvw;
  }
  .teamMember {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.25dvw;
  }
  .memberInfo {
    width: 100%;
    gap: 0.6dvw;
  }
}

@media (min-width: 1025px) and (max-height: 674px) {
  .container {
    padding-bottom: 1dvw;
  }
  .memberInfo {
    gap: 0.5vw;
  }
  .block2 {
    gap: 2vw;
  }
}
