@media (max-width: 1025px) {
  .container {
    width: 100%;
    height: 100dvh;
    background-image: url("../.././assets-web3/image/fon-section5-web3.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .content {
    width: 90%;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    gap: 7dvw;
    padding: 0 3.48dvw;
  }
  .centuries {
    display: flex;
    flex-direction: column;
    gap: 5.976dvw;
    align-items: center;
    justify-content: center;
  }
  .centuries2 {
    display: flex;
    flex-direction: column;
    gap: 1.8dvw;
    align-items: center;
    justify-content: center;
  }
  .blockAnimation {
    width: 50%;
  }

  .input {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 3.7dvw;
    color: #fff;
    background-color: transparent;
    border: 1px solid rgba(134, 234, 207, 0.5);
    border-radius: 999px;
    outline: none;
    transition: border-color 0.2s ease;
  }
  .input::placeholder {
    color: #d3d3d3;
    font-size: 3.7dvw;
    font-weight: 500;
    font-family: "Poppins";
  }
}
