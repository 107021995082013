@media (max-width: 1025px) {
    .containerBlock1 {
        width: 100%;
        height: 100dvh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .content {
        width: 90%;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: space-between;
        margin: 0 auto;
        gap: 28.13dvw;
        padding: 9.3dvw 0;
    }
    .text {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 3.25dvw;
        margin: 0 auto;
    }
}
