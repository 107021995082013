.container {
    display: flex;
    overflow-y: hidden;
    align-items: flex-end;
    height: 100dvh;
    width: 100%;
    overflow-x: hidden;
    background-image: url("../../../assets/images/sectiom-11-fon-mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #000;
    background-position: center;
    position: relative;
}

.content {
    width: clamp(258px, 26.5vw, 493px);
    margin-left: 5%;
    display: grid;
    gap: 3.2dvw;
    padding-bottom: 14.25%;
}
.imgAudio {
    position: absolute;
    bottom: 5.88dvw;
    right: 5%;
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
    .container {
        flex-direction: row;
        align-items: center;
        gap: 5dvw;
        background-color: #00cb94;
        background-image: url("../../../assets/images/fon-section-11.png");
        background-size: cover;
        background-position: center;
    }
    .content {
        padding-bottom: 0;
        gap: 1.15dvw;
    }
}
