
@media (min-width: 1025px) {
  .container {
    width: 50%;
    height: 100%;
    background-color: #9CFFE4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .content {
    width: 90%;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: auto;
    padding: 0 3.48dvw;
    gap: 4dvw;
  }
  .infoBlock {
    display: flex;
    align-items: center;
    gap: 1.1dvw;
  }
  .icon {
    width: 59px;
  }
  .blockText {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .centuries {
    display: grid;
    gap: 34px;
    width: 57%;
  }

  .centuries:first-of-type {
    gap: 24px;
    width: 60%;
  }
}

@media (max-width: 1025px) {
  .container {
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .content {
    width: 90%;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    gap: 10dvw;
    padding: 0 3.48dvw;
  }
  .infoBlock {
    display: flex;
    align-items: center;
    gap: 3.72dvw;
  }
  .icon {
    width: 59px;
  }
  .blockText {
    display: flex;
    flex-direction: column;
    gap: 1dvw;
  }
  .centuries {
    display: grid;
    gap: 5.976dvw;
  }
}
