@media (min-width: 1025px) {
    .container {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .content {
        width: 100%;
        overflow: auto;
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-left: auto;
        padding-left: 7.3dvw;
        gap: 7dvw;
    }
    .infoBlock {
        display: flex;
        align-items: center;
        gap: 1.1dvw;
    }
    .icon {
        width: 59px;
    }
    .blockText {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .centuries {
        display: grid;
        gap: 14px;        
        width: 70%;
    }
}


@media (max-width: 1025px) {
    .container {
        width: 100%;
        height: 100dvh;
        background-color: #c1e6dc;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .content {
        width: 80%;
        overflow: auto;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 9.06dvw;
        /* padding: 0 3.48dvw 10dvw; */
    }
    .infoBlock {
        display: flex;
        align-items: center;
        gap: 3.2dvw;
    }
    .icon {
        width: 59px;
    }
    .blockText {
        display: flex;
        flex-direction: column;
        gap: 1dvw;
    }
    .centuries {
        display: grid;
        gap: 6.97dvw;
    }
}
