.container {
  height: 100dvh;
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.fallbackBg {
  background-image: url("../../../assets/images/fon-section-4-mobile.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.videoBg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.blockContent {
  width: 45%;
  padding-left: 5%;
  padding-bottom: 14.25%;
}
.content {
  width: clamp(258px, 31.5vw, 493px);
  display: grid;
  gap: 3.2dvw;
}
.image {
  display: none;
}
.imgAudio {
  position: absolute;
  bottom: 12.25dvw;
  right: 5%;
}

@media (min-width: 1025px) {
  .container {
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    background-image: none;
  }

  .container.fallbackBg .image {
    display: block;
    margin: 0;
    width: 50%;
    height: 100vh;
    position: relative;
  }
  .blockContent {
    padding-bottom: 0;
    margin-bottom: 4.17dvw;
  }
  .content {
    gap: 1.15dvw;
  }
  .videoBg {
    width: 50%;
  }
  .imgAudio {
    bottom: 4.17dvw;
  }
}
