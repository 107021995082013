.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  height: 100dvh;
  width: 100%;
  overflow-x: hidden;
  flex: 1;
  margin-bottom: 60px;
  text-decoration: none;
}

.socContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.connectWith {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3f3f3f;
}

.email {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #28793e;
  text-decoration: none;
}

.icons {
  display: flex;
  width: 50%;
}

.privacy {
  font-size: 14px;
  color: #606060;
}
.members {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.cardContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
  text-decoration: none;
}

.img {
  width: 74px;
  aspect-ratio: 1;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  text-decoration-skip-ink: none;
  color: #000000;
  text-decoration: none;
}

.subTitle {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  text-decoration-skip-ink: none;
  color: #3f3f3f;
  text-decoration: none;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 22px;
  text-underline-position: from-font;
}

@media (min-width: 1025px) {
  .desktopContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .formContainer {
    flex: 1;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    padding-top: 5%;
  }

  .colabContainer {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100dvh;
    width: 100%;
    padding-top: 5%;
  }

  .socContainer {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
  }

  .privacy {
    margin-bottom: 10rem;
  }

  .connectWith,
  .email {
    font-size: 24px;
    line-height: 36px;
  }

  .touchTitle {
    font-size: 58px;
    line-height: 55px;
    width: 100%;
  }

  .touchPrgrph {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    color: #3f3f3f;
    margin-bottom: 2rem;
    width: 75%;
  }

  .contactFormContainer {
    width: 50%;
    align-self: flex-start;
  }
}
