@media (max-width: 1025px) {
    .container {
        height: 100%;
        width: 100%;
        min-height: 100dvh;
        background-color: var(--primary-green);
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
        gap: 6.976dvw;
        padding: 9.3dvw 0;
    }

    .teamBlock {
        width: 90%;
        display: grid;
        gap: 7.9dvw;
    }
    /* .teamMember {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 5.58dvw;
    }
    .avatar {
        width: 22.7dvw;
    }
    .memberInfo {
        width: 57.67dvw;
        gap: 2dvw;
        display: flex;
        flex-direction: column;
    } */
}
