.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  align-items: center;
  height: 100dvh;
  width: 100%;
  overflow-x: hidden;
  flex: 1;
  position: relative;
}

.fallbackBg {
  background-image: url("../../../assets/images/hero-bg-moss.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.videoBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.blockContent {
  box-sizing: border-box;
  display: flex;
  width: 90%;
  height: 100%;
  align-items: flex-end;
  position: relative;
  padding-bottom: 14.25%;
}
.content {
  width: clamp(288px, 34vw, 682px);
  display: grid;
  gap: 3.2dvw;
}
.p {
  /* display: none; */
}
.imgAudio {
  position: absolute;
  bottom: 12.25;
  right: 0;
}
.blockButton {
  display: flex;
  gap: 2.79dvw;
  margin-top: 7%;
}

@media (min-width: 1025px) {
  .container {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    height: auto;
    gap: 5dvw;
  }

  .blockContent {
    gap: 1.667dvw;
    padding-bottom: 1dvh;
    align-items: flex-end;
    padding-bottom: 6.35dvw;
  }
  .content {
    gap: 1.15dvw;
  }
  .p {
    display: block;
    width: 90%;
  }
  .blockButton {
    gap: 0.833dvw;
    margin-top: 2%;
  }
}
