* {
  box-sizing: border-box;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100dvh;
  gap: 56px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}
.content {
  width: 90%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 3.2dvw;
  margin-left: 5%;
  padding-bottom: 15%;
}

.containerImages {
  max-width: 760px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6.5dvw;
}
.containerImages > img {
  width: 24.6dvw;
  height: 24.6dvw;
}
.form {
  margin-top: 18.6dvw;
  width: 100%;
}
.containerBlock1 {
  width: 100%;
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
  .container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    gap: 0;
    background: linear-gradient(to right, #fff 50%, var(--primary-green) 50%);
  }
  .containerBlock1 {
    width: 45%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 3.8dvw;
  }
  .content {
    width: 60dvw;
    max-width: 493px;
    margin-left: 10%;
    gap: 1.25dvw;
    padding-bottom: 1.3dvw;
  }
  .p {
    margin-bottom: 2.3dvw;
  }

  .containerImages {
    max-width: 760px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.25dvw;
  }
  .containerImages > img {
    width: 9dvw;
    height: 9dvw;
  }
  .form {
    width: 100%;
    height: auto;
    margin-top: 0;
  }
}

@media (min-width: 1025px) and (max-height: 860px) {
  .containerBlock1 {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 0;
    height: 65vh;
    margin-bottom: 10%;
  }
}

@media (min-width: 1025px) and (max-height: 674px) {
  .containerBlock1 {
    /* padding-bottom: 1dvw; */
  }
  .content {
    height: 50vh;
    margin-bottom: 20%;
  }
  .p {
    margin-bottom: 0.8dvw;
  }
}
