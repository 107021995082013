.block {
    width: 23.7dvw;
    height: 7.44dvw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    gap: 0.5dvw;
}
.icon {
    width: 30px;
}
.text {
    display: inline-block;
    width: 5ch;
    color: var(--color-text);
}
@media (min-width: 1025px) {
    .block {
        width: 5.31dvw;
        height: 1.8dvw;
    }
}
