.container {
    width: 40%;
    height: 85dvh;
    display: flex;
    flex-direction: column;
    gap: 1.5dvw;
    margin-left: 5%;
}
.pool {
    width: 75%;
    height: 9.8dvw;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 1.56dvw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.41dvw;
}
.containerInput {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 999px;
    padding-left: 0.31dvw;
    height: 2.39dvw;
    margin-top: 0.46dvw;
}
.subscribeInput {
    width: 55%;
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    color: #fff;
    font-size: 15;
}
.subscribeInput::placeholder {
    color: var(--grey-text);
}
