.container {
  width: 100%;
  height: 100dvh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content {
  width: 90%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  gap: 8dvw;
  padding: 0 6.96dvw;
}
.infoBlock {
  display: flex;
  align-items: center;
  gap: 3.72dvw;
}
.infoBlock > img {
  width: 9.3dvw;
}
.centuries {
  display: grid;
  gap: 5.976dvw;
}
.centuries2 {
  width: 90%;
  display: grid;
  gap: 5.976dvw;
}
@media (min-width: 1025px) {
  .container {
    flex-direction: row;
    background-color: #9cffe4;
  }
  .content {
    width: 50%;
    gap: 2.6dvw;
    align-items: flex-start;
  }
  .blockImg {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.6dvw;
  }
  .centuries {
    gap: 1.25dvw;
  }
  .centuries2 {
    gap: 1dvw;
  }
  .infoBlock {
    gap: 0.83dvw;
  }
  .infoBlock > img {
    width: 2.39dvw;
  }
  .blockImg > img {
    width: 29.58dvw;
  }
}
