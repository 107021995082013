.container {
  width: 100%;
  height: 100dvh;
  background-image: url("../.././assets-web3/image/fon-section-2-mobile-web3.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.content {
  width: 90%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  gap: 3dvw;
  padding: 0 3.48dvw;
  position: relative;
  overflow: hidden;
}
.blockPresale {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3.9dvw;
}
.bgPromotion {
  width: 100%;
  height: 11.16dvw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    90deg,
    rgba(181, 124, 255, 0.4) 0%,
    rgba(253, 82, 253, 0.5) 50%,
    rgba(181, 124, 255, 0.4) 100%
  );
  backdrop-filter: blur(1px);
  border-radius: 20px;
  margin-bottom: 3dvw;
}
.textPomotion {
  width: 100%;
}

.blockPrice {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.79dvw;
  margin-bottom: 3dvw;
}

.blockCurrentListing {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.barBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3dvw;
}

.textBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.progressBar {
  width: 100%;
  height: 5.11dvw;
  border-radius: 9999px;
  overflow: hidden;
  position: relative;
}

.progressFill {
  height: 100%;
  border-radius: 9999px;
  transition: width 0.3s ease;
}

.paymentInput {
  background: #ffffff;
  color: #000000;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  text-align: center;
  padding: 1.5dvw 2dvw;
  width: 50%;
}

.paymentInput:focus {
  outline: none;
}

.paymentInput::placeholder {
  color: #000000;
}

.paymentSelect {
  background: #ffffff;
  color: #000000;
  border-radius: 30px;
  font-size: 16px;
  text-align: center;
  padding: 1.5dvw 2dvw 1.8dvw;
  width: 50%;
  border: none;
  border-right: 8px solid transparent;
}

.paymentSelect:focus {
  outline: none;
}
.blockButton {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3dvw;
}

.paymentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.walletContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.metaMask {
  background: url(../../../assets/images//metaMask.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 20.69dvw;
  height: 3.72dvw;
}

.trustWallet {
  background: url(../../../assets/svg/trustWallet.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 22.09dvw;
  height: 3.72dvw;
}

.allWallets {
  background: url(../../../assets/svg/allWallets.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 40.69dvw;
  height: 3.72dvw;
}

.walletInfo {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2.3dvw;
  /* flex-wrap: wrap; */
}

.walletBox {
  width: 19.53dvw;
  height: 8.37dvw;
  background-color: #3ad7ac;
  color: #ffffff;
  padding: 6px 14px;
  border-radius: 1.65dvw;
}
.adressBox {
  width: 38.6dvw;
  height: 8.37dvw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.disconnectBox {
  width: 24.65dvw;
  height: 8.37dvw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.networkBox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.39dvw;
}

.networkIcon {
  background: url(../../../assets/svg/ethIcon.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 18px;
  height: 18px;
}

.connectedInfo {
  background-color: hsl(164, 99%, 30%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 5.58dvw 3.5dvw;
  gap: 4.65dvw;
}

.connectedContentTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3dvw;
}

.connectedContentBottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.amountInputContainer {
  display: flex;
  flex-direction: column;
}

.inputSelectWrapper {
  display: flex;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 20px;
  background-color: transparent;
  height: 9.3dvw;
  width: 37.9dvw;
}

.amountInput {
  flex: 1;
  border: none;
  background: transparent;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 12px;
  outline: none;
  width: 60%;
}

.amountInput::placeholder {
  color: #ffffff;
}

.equalsSign {
  display: block;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
}

.receiveSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bonusCodeContainer {
  width: 166px;
  display: flex;
  flex-direction: column;
}

.bonusCodeInput {
  width: 37.9dvw;
  height: 9.3dvw;
  border: 1px solid #fff;
  border-radius: 20px;
  background: transparent;
  color: #fff;
  font-size: 3.7dvw;
  padding-left: 5%;
  outline: none;
}

.bonusCodeInput::placeholder {
  color: #aaa;
}

.buyMoreButton {
  width: 33.48dvw;
  height: 9.3dvw;
  background-color: #fff;
  color: #00cb94;
  font-size: 3.7dvw;
  font-weight: 500;
  border: none;
  border-radius: 4.6dvw;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
}

.buyMoreButton:hover {
  background-color: hsl(164, 100%, 81%);
}

.connecteLegalContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.legalNoticeLink {
  color: #fff;
  text-decoration: none;
}

.legalNoticeCheckbox {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
}

.legalNoticeCheckbox:checked {
  background-color: hsl(164, 99%, 30%);
  border-color: hsl(164, 99%, 30%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z'/%3E%3C/svg%3E");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-width: 1025px) {
  .container {
    width: 100%;
    height: 100dvh;
    background-image: url("../.././assets-web3/image/fon-web3-section1-desktop.png");
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 2%;
  }
  .content {
    width: 31.7%;
    height: auto;
    padding: 2.1dvw 1.5dvw 1.1dvw;
    border-radius: 20px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin: 0;
    margin-right: 5%;
    gap: 1dvw;
  }
  .blockPresale {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-bottom: 0;
  }
  .bgPromotion {
    width: 75%;
    height: auto;
    padding: 0.625dvw 1.35dvw;
    margin-bottom: 0;
  }
  .blockPrice {
    gap: 0.625dvw;
    margin-bottom: 0;
  }
  .blockCurrentListing {
    width: 75%;
  }
  .textBar {
    width: 100%;
  }
  .barBlock {
    width: 75%;
    justify-content: center;
    gap: 0.41dvw;
  }
  .progressBar {
    width: 100%;
    height: 1.14dvw;
    margin: 0 auto;
  }
  .blockButton {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1dvw;
  }

  .walletContainer {
    width: 100%;
    flex-wrap: nowrap;
    margin-bottom: 0;
  }

  .metaMask {
    width: 5.2dvw;
    height: 1.04dvw;
  }

  .trustWallet {
    width: 5.9dvw;
    height: 1.04dvw;
  }

  .allWallets {
    width: 10.2dvw;
    height: 1.04dvw;
  }

  .walletInfo {
    width: 100%;
    gap: 1dvw;
  }

  .walletBox {
    width: 4.37dvw;
    height: 1.87dvw;
    padding: 0;
    border-radius: 0.41dvw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .adressBox {
    width: 8.64dvw;
    height: 1.87dvw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .disconnectBox {
    width: 5.52dvw;
    height: 1.87dvw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .connectedInfo {
    width: 75%;
    border-radius: 20px;
    padding: 1.25dvw 1.45dvw;
    gap: 1dvw;
  }

  .inputSelectWrapper {
    display: flex;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 20px;
    background-color: transparent;
    height: 2.08dvw;
    width: 8.4dvw;
  }
  .amountInput {
    flex: 1;
    border: none;
    background: transparent;
    color: #ffffff;
    font-size: 0.83dvw;
    padding: 0.52dvw 0.625dvw;
    outline: none;
    width: 60%;
  }

  .amountInput::placeholder {
    color: #ffffff;
  }
  .bonusCodeInput {
    width: 8.4dvw;
    height: 2.08dvw;
    font-size: 0.83dvw;

    outline: none;
  }

  .bonusCodeInput::placeholder {
    color: #aaa;
  }
  .connectedContentBottom {
    margin-top: 20px;
    column-gap: 0;
    row-gap: 0;
    flex-wrap: nowrap;
  }
  .buyMoreButton {
    width: 7.5dvw;
    height: 2.08dvw;
    background-color: #fff;
    color: #00cb94;
    font-size: 0.83dvw;
    font-weight: 500;
    border-radius: 1dvw;
  }
  .connectedContentTop {
    gap: 1dvw;
  }
}
