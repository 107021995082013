.container {
  width: 100%;
  height: 100dvh;
  background-image: url("../.././assets-web3/image/fon-section11-web3.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content {
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  gap: 8dvw;
  padding: 0 6.96dvw;
}
.infoBlock {
  display: flex;
  align-items: center;
  gap: 3.72dvw;
}
.infoBlock > img {
  width: 9.3dvw;
}
.centuries {
  display: grid;
  gap: 5.976dvw;
}
.centuries2 {
  width: 90%;
  display: grid;
  gap: 5.976dvw;
}
@media (min-width: 1025px) {
  .container {
    background-image: url("../../assets-web3/image/fon_desctop-section11.png");
    flex-direction: row;
    justify-content: space-between;
  }
  .centuries {
    width: 82%;

    gap: 1.25dvw;
  }
  .content {
    width: 50%;
    gap: 2.6dvw;
    margin: 0;
    padding: 0;
    padding-left: 5%;
    align-items: flex-start;
  }
  .infoBlock {
    gap: 1.14dvw;
  }
  .infoBlock > img {
    width: 2.8dvw;
  }
  .centuries2 {
    width: 81%;
    background-color: rgba(0, 0, 0, 0.38);
    border-radius: 20px;
    gap: 2dvw;
    padding: 1.5dvw 2.8dvw;
  }
  .block2Desktop {
    width: 50%;
    padding-right: 5%;
    display: flex;
    justify-content: flex-end;
  }
  .blockBotton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.25dvw;
  }
  .blockSnimation {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.93dvw;
  }
}
