.section12 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  background-color: var(--primary-green);
}

.container {
  width: 100%;
  margin-top: 25%;
  margin-bottom: 15%;
  position: relative;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3.645dvw;
}
.button {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  font-size: 32px;
  height: 100vh;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
  width: 6dvw;
  object-fit: contain;
}
.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin-left: 5%;

  display: grid;
  gap: 7dvw;
}

@media (min-width: 1025px) {
  .section12 {
    height: 100vh;
  }
  .container {
    margin-left: 5%;
    margin-top: 4.0625dvw;
    margin-bottom: 0;
    margin: 0;
    width: 60%;
    gap: 3.645dvw;
    align-items: center;
  }
  .content {
    gap: 4dvw;
    display: grid;
    grid-auto-flow: column;
    margin-left: 0;
    grid-template-rows: repeat(4, auto);
  }

  .button {
    display: block;
  }
}
@media (min-width: 1025px) and (max-height: 860px) {
  .content {
    gap: 1.5dvw;
    margin-top: 3dvw;
  }
}
