.container {
  height: 100dvh;
  overflow-y: auto;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.section {
  height: auto;
}

.scrollbar {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 100%;
  background: transparent;
  z-index: 1001;
}

.scrollbarThumb {
  position: absolute;
  width: 100%;
  background: #00cb94;
}
