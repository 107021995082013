@media (min-width: 1025px) {
  .container {
    width: 100%;
    min-height: 100dvh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 3.48dvw;
  }

  .leftContent {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1.8dvw;
    padding-left: 3.48dvw;
    margin-bottom: 8dvw;
  }

  .middleContent {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rightContent {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .centuries {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
  }

  .schemaImage {
    width: 100%;
    max-width: 28dvw;
  }

  .containerColor {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
  }

  .column {
    flex: 1;
    line-height: 30px;
  }

  .column > * {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 1025px) {
  .container {
    width: 100%;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content {
    width: 90%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    gap: 1.8dvw;
    padding: 0 3.48dvw;
  }

  .content > img {
    width: 55.27dvw;
  }

  .centuries {
    display: grid;
    gap: 1dvw;
  }

  .containerColor {
    display: flex;
    width: 100%;
    gap: 7dvw;
    box-sizing: border-box;
  }

  .column {
    flex: 1;
  }
}
