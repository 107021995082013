.blockClose {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.86dvw;
    height: 6.5dvw;
}
.blockClose > img {
    width: 5.58dvw;
}
