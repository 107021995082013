.container {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  align-items: flex-start;
  justify-content: space-between;
  /* justify-content: flex-end; */
  height: 100dvh;
  width: 100%;
  overflow-x: hidden;
  position: relative;
}
.fallbackBg {
  background-image: url("../../../assets/images/fon-section-9-mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;
  background-position: center;
}
.videoBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content {
  box-sizing: border-box;
  width: clamp(258px, 28.5vw, 566px);
  margin-left: 5%;
  display: grid;
  gap: 3.2dvw;
  padding-bottom: 14.25%;
}
.imgAudio {
  position: absolute;
  bottom: 5.88dvw;
  right: 5%;
}

.fonSlogo {
  width: 55.88dvw;
  height: 55.88dvw;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  margin-top: 20%;
  margin-left: 23%;
}

.iconSLogo {
  width: 37.68dvw;
}
.blockButton {
  display: flex;
  gap: 2.79dvw;
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
  .container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5dvw;
    flex-direction: row;
  }
  .content {
    padding-bottom: 0;
    gap: 1.15dvw;
  }
  .fonSlogo {
    width: 27.13dvw;
    height: 27.13dvw;
    margin-top: 0;
    margin-left: 0%;
    margin-right: 10%;
  }
  .iconSLogo {
    width: 18.38dvw;
  }
  .blockButton {
    gap: 0.833dvw;
    margin-top: 2%;
  }
}
