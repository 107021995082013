@import "colors.css";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 15px 5%;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  z-index: 1000;
  backdrop-filter: blur(5px);
}
.headerNotFixed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 15px 5%;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  z-index: 1000;
}

.blur {
  backdrop-filter: blur(4px);
}
.burgerMemuBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.burgerMemuBlock > span {
  display: block;
  font-size: 1.04dvw;
  line-height: 1.14dvw;
  border-right: 0.1dvw solid black;
  padding-right: 1.25dvw;
}
.menuBtn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.04dvw;
  padding-left: 1.25dvw;
}
.logoContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo {
  height: clamp(34px, 1.77dvw, 40px);
  width: auto;
}

.scrbTitle {
  font-size: 1.04dvw;
  font-weight: bold;
  margin-left: 0.52dvw;
}

.socialIcons {
  display: flex;
  gap: 1.25dvw;
  justify-content: center;
  align-items: center;
}
.socialIcons > a {
  display: block;
}

.actions {
  display: flex;
  align-items: center;
  gap: 4dvw;
}
.textWhite {
  color: white;
}

.textBlack {
  color: black;
}
.menuBtn svg {
  transition: transform 0.3s ease;
}
.mobileIconContainer {
  position: relative;
  width: 30px;
  height: 30px;
}
.mobileIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center;
}
:global(.fade-enter) {
  opacity: 0;
}

:global(.fade-enter.fade-enter-active) {
  opacity: 1;
  transition: opacity 2s;
}

:global(.fade-exit) {
  opacity: 1;
}

:global(.fade-exit.fade-exit-active) {
  opacity: 0;
  transition: opacity 2s;
}

.forbes {
  width: 20dvw;
  height: 1.82dvw;
  margin-left: 2.08dvw;
}

@media (min-width: 1025px) {
  .actions {
    gap: 0.78dvw;
  }
}
