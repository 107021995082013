#team {
  background: #dcf2e7;
}

#collaborations {
  background: #dcf2e7;
}

@media (min-width: 1025px) {
  #team {
    background-color: #ffffff;
  }

  #collaborations {
    background: #ffffff;
  }
}
