.container {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  height: 100dvh;
  top: 0;
  left: 0;
  z-index: 9999;
}

.modalContent {
  transform: translateX(100%);
  opacity: 0;
  will-change: transform, opacity;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  gap: 6px;
  box-sizing: border-box;
  background: white;
  width: 100%;
  padding-top: 3dvw;
  height: 100%;
  overflow-y: auto;
  gap: 2.5dvw;
  padding-left: 10%;
  z-index: 1999;
}
.active {
  transform: translateX(0);
  opacity: 1;
}

.headerModal {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 3dvw;
  padding-bottom: 5dvw;
}
.headerModal :nth-child(2) {
  cursor: pointer;
}

.modalContent li {
  list-style-type: none;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -1%;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
}
.modalContent li:hover {
  color: #28793e;
}

.blur {
  opacity: 0;
  transition: opacity 0.5s ease-out;
  position: fixed;
  height: 100dvh;
  width: 100%;
  top: 0;
  z-index: -2;
  background-color: #cecece4f;
  backdrop-filter: blur(15px);
  cursor: pointer;
}
.blur.active {
  opacity: 1;
}

@media (min-width: 1025px) {
  .modalContent {
    position: absolute;
    width: 30%;
    max-height: 100vh;
    overflow-y: auto;
    right: 0;
    height: calc(100dvh);
    padding-left: 3%;
    padding-top: 4dvh;
    gap: 3.5dvh;
    padding-left: 5%;
  }
  .headerModal {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 1.5dvw;
  }
  .modalContent li {
    font-size: 3.12dvh;
    line-height: 3.12dvh;
  }
  .socContainer {
    margin-top: 0;
  }
}
