.container {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  box-sizing: border-box;
  position: relative;
  justify-content: center;
}

.title {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
  .container {
    justify-content: flex-start;
    align-items: flex-start;
    height: 100dvh;
  }

  .title {
    font-size: 58px;
    line-height: 55px;
    width: 50%;
  }
}
