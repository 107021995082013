.container {
  width: 100%;
  height: 100dvh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 23.02dvw;
  position: relative;
}
.fallbackBg {
  background-image: url("../.././assets-web3/image/fon-section7-web3.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #000;
}
.videoBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.centuries {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5.58dvw;
}
.container > img {
  width: 49.69dvw;
}
@media (min-width: 1025px) {
  .container {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5%;
  }
  .centuries {
    width: 38%;
    align-items: flex-start;
    gap: 1.25dvw;
  }
  .networkTockenBlock {
    width: 40%;
  }
  .networkTockenBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .network {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1.66dvw;
  }
  .network > img {
    width: 16.35dvw;
  }
  .network {
    position: relative;
  }
  .token {
    position: absolute;
    right: 17%;
  }
}
