.container {
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.content {
    width: 95%;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    gap: 8dvw;
    padding: 0 3.48dvw;
}

.centuries {
    display: grid;
    gap: 5.976dvw;
}
.blockInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 3.95dvw;
}
.card > img {
    width: 24px;
    height: 24px;
}
.card {
    display: flex;
    gap: 2.79dvw;
}
.networkTockenBlock {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.network {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2.55dvw;
}
.network > img {
    width: 21.86dvw;
}
@media (min-width: 1025px) {
    .container {
        height: auto;
        align-items: flex-start;
    }
    .card {
        gap: 0.65dvw;
    }
    .card > img {
        width: 1.6dvw;
        height: 1.6dvw;
    }
    .blockInfo {
        gap: 0.83dvw;
    }
    .centuries {
        gap: 1.77dvw;
    }
    .content {
        padding: 0;
        margin: 0;
        align-items: flex-start;
    }
}
