.container {
  width: 90%;
  height: 100dvh;
  display: flex;
  align-items: center;
  gap: 25px;
  margin: 0 auto;
  justify-content: center;
  padding-top: 7%;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.3dvw;
}
.cardContainer {
  max-width: 258px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1dvw;
}
.cardContainer > span {
  display: inline-block;
  text-align: center;
}
.cardContainer > span > a {
  text-decoration: none;
  border-bottom: 2px solid;
  border-color: var(--color-text-grey);
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
  .container {
    width: 80%;
    display: flex;
    padding-top: 0;
  }
  .content {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    gap: 9.8dvw;
  }
  .cardContainer {
    align-items: flex-start;
    max-width: 360px;
    gap: 1dvw;
  }
  .cardContainer > span {
    text-align: left;
  }
}
