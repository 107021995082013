.container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  height: 100dvh;
  background-image: url("../../../assets/images/fon-sectionscriber.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.blockContent {
  width: 50%;
  padding-left: 5%;
  padding-bottom: 14.25%;
}
.content {
  width: clamp(258px, 29.5vw, 585px);
  display: grid;
  gap: 3.2dvw;
  margin-top: 4.65dvw;
}

.image {
  display: none;
}
.imageSblack {
  position: absolute;
  width: 55.88dvw;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
.imgAudio {
  position: absolute;
  bottom: 12.25dvw;
  right: 5%;
}
@media (max-width: 767px) and (min-height: 421px) {
  .imageSblack {
    top: 12%;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}
/* Desktop-specific styles */
@media (min-width: 1025px) {
  .container {
    align-items: center;
    justify-content: space-between;
    height: auto;
    background-image: none;
  }
  .image {
    display: block;
    margin: 0;
    width: 50%;
    height: 100vh;
    position: relative;
  }
  .imageSblack {
    width: 27.34dvw;
    position: absolute;
    top: 50%;
    left: 76%;
    z-index: 1;
    transform: translate3d(-50%, -50%, 0);
  }
  .blockContent {
    padding-bottom: 0;
    margin-bottom: 4.17dvw;
  }
  .content {
    gap: 1.15dvw;
    margin-top: 0;
  }
  .imgAudio {
    bottom: 4.17dvw;
  }
}
