html,
body {
  touch-action: pan-y; /* Разрешаем только вертикальную прокрутку */
  -webkit-touch-callout: none; /* Отключаем вызов контекстного меню на iOS */
  -webkit-user-select: none; /* Отключаем выделение текста */
  user-select: none;
  -webkit-text-size-adjust: 100%; /* Запрещаем автоизменение размера шрифта */
}
body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
  font-family:
    "Helvetica ",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica ",
    sans-serif;
}

code {
  font-family:
    "Helvetica " source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

button {
  cursor: pointer;
  border: none;
}

/* @font-face {
  font-family: "Poppins";
  src: url("/src/assets/fonts/Poppins-ExtraBold.ttf") format("truetype"); 
  font-weight: 900;
  font-style: normal;
} */
@font-face {
  font-family: "Poppins";
  src: url("/src/assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
h1,
h2 {
  font-family: "Poppins";
  margin: 0;
}

p,
h3,
h4,
h5,
h6 {
  margin: 0;
}
