.timerBlock {
  width: 100%;
  height: 15.81dvw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.44);
  border-radius: 20px;
  color: var(--color-text);
  font-size: 22px;
  padding: 10dvw 16dvw;
}
.timerBlock > div {
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.time {
  font-size: 8.3dvw;
}
.textTime {
  font-size: 3.9dvw;
}
span {
  display: block;
}
@media (min-width: 1025px) {
  .timerBlock {
    width: 75%;
    height: auto;
    margin: 0 auto;
    padding: 12px 0;
  }
  .time {
    font-size: 1.8dvw;
  }
  .textTime {
    font-size: 0.885dvw;
  }
}
