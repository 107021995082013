.form {
  display: flex;
  flex-direction: column;
  gap: 2dvw;
  font-family: Arial, sans-serif;
}
.input {
  margin-bottom: 3.58dvw;
  border: none;
  outline: none;
  background: transparent;
}
.textarea {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  padding: 0;
  resize: none;
  margin-bottom: 0;
}

.input::placeholder,
.textarea::placeholder {
  color: var(--grey-text);
  font-size: 18px;
  line-height: 22px;
}

.textarea {
  resize: none;
}
@media (min-width: 1025px) {
  .form {
    gap: 1dvw;
  }
  .input {
    margin-bottom: 0;
    height: 28px;
  }
  .input::placeholder,
  .textarea::placeholder {
    font-size: 1.25dvw;
  }
}
