.container {
  height: 100dvh;
  width: 100%;
  display: flex;
  align-items: flex-end;
  background-image: url("../../../assets/images/section-10-mobile-fon.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.blockContent {
  width: 45%;
  padding-left: 5%;
  padding-bottom: 14.25%;
}
.content {
  width: clamp(258px, 25.5vw, 493px);
  display: grid;
  gap: 3.2dvw;
}
.image {
  display: none;
}
.imgAudio {
  position: absolute;
  bottom: 5.88dvw;
  right: 5%;
}
.blockButton {
  display: flex;
  gap: 2.79dvw;
}
/* Desktop-specific styles */
@media (min-width: 1025px) {
  .container {
    justify-content: space-between;
    align-items: center;
    height: auto;
    background-image: none;
  }

  .image {
    display: block;
    margin: 0;
    width: 50%;
    height: 100vh;
    position: relative;
  }
  .blockContent {
    padding-bottom: 0;
    margin-bottom: 4.17dvw;
  }
  .content {
    gap: 1.15dvw;
  }
  .blockButton {
    gap: 0.833dvw;
    margin-top: 2%;
  }
}
