.button {
    padding: 1.9dvw 5.58dvw;
    font-size: clamp(14px, 1dvw, 16px);
    font-family: inherit;
    border-radius: var(--border-radius);
    border: 2px solid transparent;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    font-weight: 500;
    user-select: none;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    max-width: max-content;
    min-width: auto;
}
.inactive {
    opacity: 0.5;
    cursor: not-allowed;
}
.green {
    background-color: transparent;
    border-color: var(--primary-green);
    color: var(--color-text);
    backdrop-filter: blur(2px);
}
.green:hover:not(.inactive) {
    background-color: var(--primary-green);
    color: var(--color-text);
    border-color: #00cb94;
}
.white {
    background-color: transparent;
    border-color: var(--color-text);
    color: var(--color-text);
    backdrop-filter: blur(2px);
}
.white:hover:not(.inactive) {
    background-color: var(--color-text);
    color: var(--primary-green);
    border-color: var(--color-text);
}
.gradient {
    background-color: var(--primary-green);
    border: none;
    color: var(--color-text);
}

.gradientInactive {
    background-color: #01a478;
    color: var(--color-text);
}
.disabled {
    cursor: not-allowed;
}
@media (min-width: 1025px) {
    .button {
        padding: 0.5dvw 2dvw;
    }
}
