.link {
    display: grid;
    gap: 1.56dvw;
}
.link > a {
    display: block;
    text-decoration: none;
    color: var(--color-text-grey);
    font-size: 22px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -1%;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    cursor: pointer;
}
@media (min-width: 1025px) {
    .link > a {
        font-size: 1.14dvw;
        line-height: 1.25dvw;
    }
}
