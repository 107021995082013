.container {
  display: flex;
  overflow-y: hidden;
  align-items: flex-end;
  height: 100dvh;
  width: 100%;
  overflow-x: hidden;
  position: relative;
}
.fallbackBg {
  background-image: url("../../../assets/images/fon-section-5-mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.videoBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content {
  width: clamp(258px, 35.5vw, 566px);
  margin-left: 5%;
  display: grid;
  gap: 3.2dvw;
  padding-bottom: 14.25%;
}
.imgAudio {
  position: absolute;
  bottom: 5.88dvw;
  right: 5%;
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
  .container {
    flex-direction: row;
    align-items: center;
    gap: 5dvw;
  }
  .content {
    padding-bottom: 0;
    /* max-width: 450px; */
    gap: 1.15dvw;
  }
}
