.infoItem {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.62dvw;
}

.icon {
    width: 24px;
    height: 24px;
}
@media (min-width: 1025px) {
    .iconWrapper {
        gap: 0.36dvw;
    }
}
