.container {
  display: flex;
  overflow-y: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100dvh;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  gap: 4.186dvw;
}
.fallbackBg {
  background-image: url("../../../assets/images/gren-fon-mobil.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.videoBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.content {
  width: clamp(258px, 29.5vw, 566px);
  margin-left: 5%;
  display: grid;
  gap: 3.2dvw;
  /* position: relative; */
  padding-bottom: 14.25%;
}
.imgAudio {
  position: absolute;
  bottom: 5.88dvw;
  right: 5%;
}
.animationBlock {
  width: 90%;
  margin-left: 5%;
  display: grid;
  gap: 4.8dvw;
}
.blockButton {
  display: flex;
  gap: 2.79dvw;
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
  .container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.56dvw;
  }
  .content {
    padding-bottom: 0;
    gap: 1.15dvw;
  }
  .animationBlock {
    width: 25%;
    margin-left: 5%;
    display: grid;
    gap: 0.937dvw;
  }
  .blockButton {
    gap: 0.833dvw;
    margin-top: 2%;
  }
}
