.container {
  height: 100dvh;
  overflow: hidden;
  position: relative;
  touch-action: pan-y;
  width: 100%;
}

.containerSection10 {
  height: 100dvh;
  overflow-y: scroll;
  position: relative;
  touch-action: pan-y;
  width: 100%;
}

.content {
  transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
  touch-action: none;
  width: 100%;
  position: relative;
}

.section {
  height: 100dvh;
  flex-shrink: 0;
  width: 100%;
}

.scrollbar {
  position: fixed;
  top: 0;
  right: 0;
  width: 8px;
  height: 100%;
  background: #f2f2f244;
  z-index: 1001;
}

.scrollbarThumb {
  position: absolute;
  width: 100%;
  background: #00cb94;
  transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
}
