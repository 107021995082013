.scroll-container {
  height: 100dvh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  position: relative;
}

.scroll-section {
  overflow: hidden;
  height: 100dvh;
  scroll-snap-align: start;
  position: relative;
  box-sizing: border-box;
}

.scroll-container::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}
.scrollbar {
  background: transparent;
}

.scroll-container::-webkit-scrollbar-thumb {
  background: #00cb94;
  border-radius: 0;
}

.scroll-container::-webkit-scrollbar-track {
  height: calc(100dvh - 80px) !important;
  background: transparent;
}
