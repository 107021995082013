.container {
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content {
  width: 90%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  gap: 16.51dvw;
  padding: 0 3.48dvw;
}
.centuries {
  display: grid;
  gap: 5.976dvw;
}
.infoBlock {
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 9.3dvw;
  justify-items: center;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2.79dvw;
}

img {
  width: 21.86dvw;
}
ol {
  padding-left: 0;
  margin-left: 0;
  margin: 0;
  list-style-position: inside;
}
@media (min-width: 1025px) {
  .container {
    height: 100dvh !important;
  }
  .content {
    gap: 6.25dvw;
    padding: 0;
  }
  .infoBlock {
    width: 90%;
    display: flex;
    align-items: start;
    justify-content: space-between;

    gap: 0;
    justify-items: center;
  }
  .centuries {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 65%;
    gap: 1.25dvw;
  }
  .card {
    width: 15.88dvw;
    gap: 2.08dvw;
  }
}
